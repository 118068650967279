


























import { Component, Vue } from 'vue-property-decorator';
import CircleButton from '@/components/CircleButton.vue';
import Modal from '@/components/Modal.vue';

import pageContent from '@/contents/chat';

@Component({
  components: {
    CircleButton,
    Modal,
  },
})
export default class Education extends Vue {
  public modalOpen = '';

  public pageContent = pageContent;

  mounted() {
    if ((window as any).userlike) {
      (window as any).userlike.userlikeShowButton();
      (window as any).userlike.userlikeQuitChat();
    } else {
      const chatScript = document.createElement('script');
      chatScript.setAttribute('id', 'chat-script');
      chatScript.setAttribute('src', 'https://userlike-cdn-widgets.s3-eu-west-1.amazonaws.com/5ffed50aed3042eea46743c4f911b5c42c52078536624ea1b44c641ccc1fa67e.js');
      document.body.appendChild(chatScript);
    }
  }

  destroyed() {
    if ((window as any).userlike) {
      (window as any).userlike.userlikeQuitChat();
      (window as any).userlike.userlikeHideButton();
    }
  }

  openMail(to: string) {
    window.open(`mailto:${to}`, '_blank');
  }
}
