import PageContents from '@/types/PageContents';

// const distanceX = 7.5;

const left = '39%';

const pageContent: PageContents[] = [
  {
    button: {
      top: '69%',
      left: '14%',
      type: 'mail',
      content: '<em>Sales Manager Germany</em>Mario Ringelmann<em style="font-size:14px;">Mobile: +49 171 332 316 8</em>',
      class: 'chat-mail-link',
    },
    modal: {
      content: 'Mario.Ringelmann@joimax.com',
    },
  },
  {
    button: {
      top: '69%',
      left: '74%',
      type: 'mail',
      content: '<em>Customer Care Manager</em>Monika Reich<em style="font-size:14px;">Phone: +49 721 255 14-426</em>',
      class: 'chat-mail-link',
    },
    modal: {
      content: 'monika.reich@joimax.com',
    },
  },

  {
    button: {
      top: '37%',
      left,
      type: 'mail',
      content: '<em>Senior Area Sales Manager</em><em>West Germany</em>Fatima Ferreira Geitz',
    },
    modal: {
      content: 'Fatima.FerreiraGeitz@joimax.com',
    },
  },
  {
    button: {
      top: '49%',
      left,
      type: 'mail',
      content: '<em>Sales & Application Specialist</em><em>North</em>Miriam Steinmetz',
    },
    modal: {
      content: 'Miriam.Steinmetz@joimax.com',
    },
  },
  {
    button: {
      top: '60%',
      left,
      type: 'mail',
      content: '<em>Sales & Application Specialist</em><em>South Germany</em>Anne-Kathrin Petzold',
    },
    modal: {
      content: 'Anne-Kathrin.Petzold@joimax.com',
    },
  },
  {
    button: {
      top: '71%',
      left,
      type: 'mail',
      content: '<em>Sales & Application Manager</em><em>Austria</em>Herwig Friedl-Zwanzger',
    },
    modal: {
      content: 'Herwig.Friedl-Zwanzger@joimax.com',
    },
  },
];

export default pageContent;
